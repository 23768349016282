<template>
  <div class="transfer">
    <div class="heading">
      <h2>Login Password</h2>
      <p>Change your login password</p>
    </div>

    <form class="form" @submit.prevent="submitForm" novalidate="true">
      <div class="form__group">
        <label for="currentPassword">
          <input
            id="currentPassword"
            :type="passwordFieldTypeThree"
            name="currentPassword"
            class="form__input"
            placeholder="Current Password"
            v-model="$v.form.passwordCurrent.$model"
            :class="{
              'is-invalid': submitted && $v.form.passwordCurrent.$error
            }"
        /></label>
        <span @click="switchVisibilityThree" class="form__password">{{
          passwordFieldTypeThree === "password" ? "Show" : "Hide"
        }}</span>
        <span v-if="submitted && $v.form.passwordCurrent.$error" class="error">
          <small class="error" v-if="!$v.form.passwordCurrent.required"
            >Current Password is required</small
          >
          <br />
          <small class="error" v-if="!$v.form.passwordCurrent.strongPassword"
            >Strong passwords need to have a letter, a number, a special
            character, and be more than 8 characters long.</small
          >
        </span>
      </div>

      <div class="form__group">
        <label for="password">
          <input
            id="password"
            :type="passwordFieldType"
            name="password"
            class="form__input"
            placeholder="New Password"
            v-model="$v.form.password.$model"
            :class="{ 'is-invalid': submitted && $v.form.password.$error }"
        /></label>
        <span @click="switchVisibility" class="form__password">{{
          passwordFieldType === "password" ? "Show" : "Hide"
        }}</span>
        <span v-if="submitted && $v.form.password.$error" class="error">
          <small class="error" v-if="!$v.form.password.required"
            >New Password is required</small
          >
          <br />
          <small class="error" v-if="!$v.form.password.strongPassword"
            >Strong passwords need to have a letter, a number, a special
            character, and be more than 8 characters long.</small
          >
        </span>
      </div>

      <div class="form__group">
        <label for="passwordConfirm">
          <input
            id="passwordConfirm"
            :type="passwordFieldTypeTwo"
            name="passwordConfirm"
            class="form__input"
            placeholder="Confirm Password"
            v-model="$v.form.passwordConfirm.$model"
            :class="{
              'is-invalid': submitted && $v.form.passwordConfirm.$error
            }"
        /></label>
        <span @click="switchVisibilityTwo" class="form__password">{{
          passwordFieldTypeTwo === "password" ? "Show" : "Hide"
        }}</span>
        <span v-if="submitted && $v.form.passwordConfirm.$error" class="error">
          <small class="error" v-if="!$v.form.passwordConfirm.required"
            >Confirm password is required</small
          >
          <br />
          <small class="error" v-if="!$v.form.passwordConfirm.sameAsPassword"
            >Passwords do not match.</small
          >
        </span>
      </div>
      <button type="submit" class="btn btn__red btn__group">
        <loading v-if="loading" />
        <span>Continue</span>
      </button>
    </form>
  </div>
</template>

<script>
import api from "@/api/api.js";
import { mapState } from "vuex";
import { required, sameAs } from "vuelidate/lib/validators";
export default {
  computed: mapState(["user"]),
  data() {
    return {
      passwordFieldType: "password",
      passwordFieldTypeTwo: "password",
      passwordFieldTypeThree: "password",
      loading: false,
      submitted: false,
      form: {
        password: "",
        passwordConfirm: "",
        passwordCurrent: ""
      }
    };
  },
  validations: {
    form: {
      password: {
        required,
        strongPassword(password) {
          return (
            /[a-z]/.test(password) &&
            /[0-9]/.test(password) &&
            /\W|_/.test(password) &&
            password.length >= 8
          );
        }
      },
      passwordConfirm: { required, sameAsPassword: sameAs("password") },
      passwordCurrent: {
        required,
        strongPassword(password) {
          return (
            /[a-z]/.test(password) &&
            /[0-9]/.test(password) &&
            /\W|_/.test(password) &&
            password.length >= 8
          );
        }
      }
    }
  },
  methods: {
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    switchVisibilityTwo() {
      this.passwordFieldTypeTwo =
        this.passwordFieldTypeTwo === "password" ? "text" : "password";
    },
    switchVisibilityThree() {
      this.passwordFieldTypeThree =
        this.passwordFieldTypeThree === "password" ? "text" : "password";
    },
    clearData() {
      this.form.password = "";
      this.form.passwordConfirm = "";
      this.form.passwordCurrent = "";
      this.submitted = false;
    },
    submitForm() {
      this.submitted = true;

      this.$v.form.$touch();

      if (this.$v.form.$invalid) {
        return;
      }

      let payload = {
        userId: this.user.profileInfo.SMID,
        oldpassword: this.form.passwordCurrent,
        newpassword: this.form.password
      };

      this.loading = true;

      api
        .changeLoginPassword(payload)
        .then(response => {
          this.loading = false;
          let responseStatus = response.Status;
          let responseMessage = response.Message;
          if (responseStatus) {
            this.$message({
              showClose: true,
              message: `${responseMessage.charAt(0).toUpperCase() +
                responseMessage.slice(1)}`,
              type: "success",
              duration: 10000
            });
            this.clearData();
          } else {
            this.$message({
              showClose: true,
              message: `${responseMessage.charAt(0).toUpperCase() +
                responseMessage.slice(1)}`,
              type: "error",
              duration: 10000
            });
          }
        })
        .catch(error => {
          this.loading = false;
          this.$message({
            showClose: true,
            message: `${error}`,
            type: "error",
            duration: 10000
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_form.scss";
</style>
